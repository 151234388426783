.react-flow__edge-textbg {
    fill:#fff;
    fill-opacity: 1;
    stroke: #6f6f6f;
    stroke-width:2;
    cursor: pointer;
}

.react-flow__edge-text {
    font-size:11px;
    font-weight: bold;
    fill: #6f6f6f;
    cursor: pointer;
}
.selected  .react-flow__edge-textbg{
    fill:yellow;
    stroke: #ffd100;
}

.sorthelp {
    z-index:10000;
}

.react-flow__edge-path {
    stroke-width: 2;
    stroke: #444;
}